export default {
    "blue": "#00a1de",
    "red": "#c60c30",
    "green": "#009b3a",
    "orange": "#f9461c",
    "purple": "#522398",
    "yellow": "#f9e300",
    "pink": "#e27ea6",
    "brown": "#62361b",
    "combined": "#565a5c"
} as Record<string, string>