import './incidentMap.css';
import lines from "./assets/lines.json";
import stations from "./assets/stations.json";
import {TileLayer, MapContainer, GeoJSON} from "react-leaflet";
import {GeoJsonObject} from 'geojson';
import "leaflet/dist/leaflet.css";
import L, {CircleMarker, LatLng, Layer, LeafletMouseEvent} from "leaflet";
import lineAliases from "./data/lineAliases";
import lineColors from "./data/lineColors";
import * as React from "react";
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


L.Marker.prototype.options.icon = L.icon({
    iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png"
});

const center = { lat: 41.882854, lng: -87.63043 };

export default function IncidentMap() {
    const lineStyle = (feature: any) => {
        let line = lineAliases[feature.properties.LINES]
        let color = lineColors["combined"];
        if(line != undefined) {
            color = lineColors[line];
        }
        return {
            fillColor: color,
            weight: 3.5,
            opacity: 1,
            color: color
        };
    }

    const stationsPointToLayer = (feature: any, latlng: LatLng) => {
        let line = lineAliases[feature.properties.LINES]
        let color = lineColors["combined"];
        if(line != undefined) {
            color = lineColors[line];
        }
        return new CircleMarker(latlng, {
            radius: 10,
            color: color,
            className: 'blinking'
        });
    }

    const onStationClick = (event: any) => {
        setText(event.target.feature.properties.LONGNAME)
        handleClick()
    }

    const onEachStation = (feature: any, layer: Layer) => {
        layer.on({
            click: onStationClick
        });
    }

    const [open, setOpen] = React.useState(false);
    const [text, setText] = React.useState("");

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>
            <div>
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message={text}
                    action={action}
                />
            </div>
            <MapContainer
                style={{ height: "100vh", width: "100vw" }}
                center={center}
                zoom={15}
                minZoom={11}
                maxZoom={18}
                maxBounds={[
                    [41.64696, -87.99734],
                    [42.10351, -87.54214]
                ]}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://api.mapbox.com/styles/v1/noahhusby/ckys1hvkv0so614o7zpwwnpns/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoibm9haGh1c2J5IiwiYSI6ImNrZXhkYnl2ejBueDcyeXA5Y3lwMTVsbzUifQ.nicuwnmnb2HL9FXMSiV2Yw"
                />
                <GeoJSON data={lines as GeoJsonObject} style={lineStyle} />
                <GeoJSON data={stations as GeoJsonObject} pointToLayer={stationsPointToLayer} onEachFeature={onEachStation} />
            </MapContainer>
        </div>
    );
}
