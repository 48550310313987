export default {
    "Blue Line": "blue",
    "Blue Line (O'Hare)": "blue",
    "Blue Line (Forest Park)": "blue",
    "Blue Line (Congress)": "blue",
    "Red Line": "red",
    "Green Line": "green",
    "Green Line (Lake)": "green",
    "Green Line (Englewood)": "green",
    "Orange Line": "orange",
    "Purple Line": "purple",
    "Purple (Express)": "purple",
    "Purple Line: Evanston Express": "purple",
    "Yellow Line": "yellow",
    "Pink Line": "pink",
    "Pink": "pink",
    "Brown Line": "brown",
} as Record<string, string>