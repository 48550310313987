import './App.css';
import "reflect-metadata";
import * as React from 'react';
import { Routes, Route, Link } from "react-router-dom";
import IncidentMap from "./incidentMap";
import Filter from "./Filter";


export default function App() {
    return (
        <Routes>
            <Route path = "test" element={<Filter />}/>
            <Route path = "incidents" element={<IncidentMap />}/>
        </Routes>
    );
}